import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";

const COLORS = {
    portfolio: "#3b82f6", // Cor do Portfólio
};

export default function PortfolioAlphaMetrics({ alphas, type }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [displayedText, setDisplayedText] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);
    const typingSpeed = 3; // Velocidade de digitação (ms por caractere)
    const scrollRef = useRef(null); // Ref para o div que conterá o texto

    function formatBRL(number) {
        if (number === undefined || number === null) {
            return "R$ 0,00";
        }
        return number.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
        });
    }

    const openModal = (content) => {
        setModalContent(content);
        setDisplayedText(''); // Reseta o texto mostrado
        setCurrentIndex(0); // Reseta o índice atual para o efeito de digitação
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalContent('');
        setDisplayedText('');
        setCurrentIndex(0);
    };

    useEffect(() => {
        if (isModalOpen && currentIndex < modalContent.length) {
            const timeout = setTimeout(() => {
                setDisplayedText((prev) => prev + modalContent[currentIndex]);
                setCurrentIndex((prev) => prev + 1);
                if (scrollRef.current) {
                    scrollRef.current.scrollTop = scrollRef.current.scrollHeight; // Rola para o final
                }
            }, typingSpeed);

            return () => clearTimeout(timeout);
        }
    }, [isModalOpen, currentIndex, modalContent]);

    const renderMetrics = (alphas) => {
        return alphas.map((alpha, index) => (
            <div key={index} className="shadow-1 br-1 p-2 mt-2 mb-2">
                <h3>{`${alpha.metrics.alpha_code} - ${alpha.metrics.alpha_description}`}</h3>
                <div className="prsy-grid prsy-col4 justify-content-between text-center mt-3">
                    <div className="flex flex-column border-right-1 border-200">
                        <span style={{ fontSize: "1.2em", fontWeight: "bold", color: COLORS.portfolio }}>
                            {formatBRL(alpha.metrics.total_em_reais)}
                        </span>
                        <span style={{ fontSize: "0.7em" }}>Retorno Total</span>
                    </div>
                    <div className="flex flex-column border-right-1 border-200">
                        <span style={{ fontSize: "1.2em", fontWeight: "bold", color: COLORS.portfolio }}>
                            {formatBRL(alpha.metrics.drawdown_maximo_em_reais)}
                        </span>
                        <span style={{ fontSize: "0.7em" }}>Drawdown Máximo</span>
                    </div>
                    <div className="flex flex-column">
                        <span style={{ fontSize: "1.2em", fontWeight: "bold", color: COLORS.portfolio }}>
                            {`${(alpha.metrics.acuracia || 0).toFixed(2)}%`}
                        </span>
                        <span style={{ fontSize: "0.7em" }}>Assertividade</span>
                    </div>
                    <div className="flex flex-column">
                        <span style={{ fontSize: "1.2em", fontWeight: "bold", color: COLORS.portfolio }}>
                            {formatBRL(alpha.metrics.payout_diario_em_reais)}
                        </span>
                        <span style={{ fontSize: "0.7em" }}>Payoff</span>
                    </div>
                </div>
            </div>
        ));
    };

    return (
        <div>
            {/* Modal Component */}
            {isModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button onClick={closeModal}>&times;</button>
                        </div>
                        <div
                            ref={scrollRef}
                            className="modal-body"
                            style={{ overflowY: 'auto', maxHeight: '400px' }}
                        >
                            {displayedText}
                        </div>
                        <div className="modal-footer" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                            <Button
                                label="Fechar"
                                type="button"
                                className="p-button-raised"
                                onClick={closeModal}
                            />
                        </div>
                    </div>
                </div>
            )}

            {/* Métricas dos Alphas */}
            <div className="mt-4">
                <h2>{type}</h2>
                {renderMetrics(alphas)}
            </div>
        </div>
    );
}
