import React, { useState } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import FaqCategory from "./FaqCategory";
import ReactGA from "react-ga4";

export default function FaqNew() {
    ReactGA.send({
        hitType: "pageview",
        page: "/faq",
        title: "FAQ / Sobre a AMC",
    });

    const faqData = [
        {
            category: "Quem Somos",
            subcategories: [
                {
                    subcategory: "Visão Geral",
                    questions: [
                        {
                            question: "O que é o Alpha Monkey Club (AMC)?",
                            answer: "O Alpha Monkey Club é uma solução avançada de Big Data e Machine Learning voltada para o mercado financeiro, especializada em previsões de alta e baixa, tendências de curto e médio prazo, e automação de operações nas principais corretoras do Brasil."
                        },
                        {
                            question: "Qual é a missão do Alpha Monkey Club?",
                            answer: "Nossa missão é democratizar o acesso a tecnologias usadas por grandes fundos quantitativos, permitindo que investidores de todos os níveis tomem decisões mais informadas e baseadas em dados.",
                        },
                        {
                            question: "Quais são os diferenciais do Alpha Monkey Club?",
                            answer: "Previsões baseadas em análise quantitativa preditiva, controle de drawdown, automação nas principais corretoras e uma interface acessível para investidores de todos os níveis."
                        },
                    ],
                },
                {
                    subcategory: "História e Origem",
                    questions: [
                        {
                            question: "Qual é a origem do nome 'Alpha Monkey Club'?",
                            answer: "O nome combina o termo 'alpha', que simboliza desempenho superior no mercado financeiro, com 'Monkey Club', refletindo a volatilidade e a imprevisibilidade do mercado."
                        },
                        {
                            question: "Como o Alpha Monkey Club começou?",
                            answer: "O Alpha Monkey Club foi fundado para oferecer aos pequenos investidores as mesmas tecnologias avançadas utilizadas por grandes fundos como Medallion e D.E. Shaw."
                        },
                        {
                            question: "Quais são os principais marcos do Alpha Monkey Club?",
                            answer: "Participação no programa NVIDIA Inception e a implementação de tecnologias avançadas de Machine Learning e Big Data para investidores de varejo."
                        },
                    ],
                },
            ],
        },
        {
            category: "Planos e Produtos",
            subcategories: [
                {
                    subcategory: "Portfólios",
                    questions: [
                        {
                            question: "Quais portfólios estão disponíveis?",
                            answer: "Oferecemos os portfólios PF150, PF250 e PF300, cada um com características e retornos ajustados ao perfil do investidor."
                        },
                        {
                            question: "Qual é a diferença entre os portfólios PF150, PF250 e PF300?",
                            answer: "Os portfólios variam em termos de capital necessário, drawdown máximo e retorno esperado. PF150 é ideal para investidores conservadores, enquanto PF300 é indicado para investidores mais agressivos."
                        },
                        {
                            question: "Os portfólios são adequados para quais tipos de investidores?",
                            answer: "Os portfólios são projetados para atender investidores de todos os níveis, desde iniciantes que buscam estabilidade até investidores avançados interessados em maiores retornos."
                        },
                        {
                            question: "Como os portfólios geram retorno?",
                            answer: "Os portfólios combinam previsões de alta e baixa, identificam tendências de mercado e automatizam operações para maximizar ganhos e controlar o drawdown."
                        },
                        {
                            question: "Como posso contratar um portfólio?",
                            answer: "Você pode contratar um portfólio diretamente pelo nosso site. Basta selecionar o portfólio desejado e seguir as instruções."
                        },
                    ],
                },
            ],
        },
        {
            category: "Tecnologias",
            subcategories: [
                {
                    subcategory: "Análise Quantitativa",
                    questions: [
                        {
                            question: "O que é análise quantitativa preditiva?",
                            answer: "É o uso de modelos matemáticos para identificar padrões e prever movimentos de mercado, baseando-se em dados históricos e atuais."
                        },
                        {
                            question: "Quais tecnologias são usadas pelo Alpha Monkey Club?",
                            answer: "Utilizamos Big Data, Machine Learning e algoritmos avançados similares aos usados por fundos como Medallion e D.E. Shaw."
                        },
                        {
                            question: "Como a tecnologia controla o drawdown?",
                            answer: "Nossos modelos monitoram continuamente os mercados, ajustando automaticamente as operações para minimizar perdas e garantir estabilidade."
                        },
                    ],
                },
                {
                    subcategory: "Automação",
                    questions: [
                        {
                            question: "Como funciona a automação de operações?",
                            answer: "A automação executa operações de compra e venda de forma totalmente automática nas principais corretoras do Brasil, como XP, Rico e Clear."
                        },
                        {
                            question: "Posso acompanhar as operações realizadas?",
                            answer: "Sim, todas as operações são registradas e podem ser acompanhadas pelo painel do investidor."
                        },
                        {
                            question: "A automação é segura?",
                            answer: "Sim, utilizamos protocolos avançados de segurança e transparência em todas as operações."
                        },
                    ],
                },
            ],
        },
        {
            category: "Suporte Continuo",
            subcategories: [
                {
                    subcategory: "Como Funciona",
                    questions: [
                        {
                            question: "Como funciona o suporte no Alpha Monkey Club?",
                            answer: "Oferecemos suporte pelo WhatsApp 24x7 para dúvidas, cancelamentos, configuração de automações e sugestões."
                        },
                        {
                            question: "O suporte técnico está disponível em quais horários?",
                            answer: "Nosso suporte técnico especializado está disponível nos dias úteis, das 9h às 19h."
                        },
                    ],
                },
                {
                    subcategory: "Dúvidas e Sugestões",
                    questions: [
                        {
                            question: "Como posso enviar sugestões?",
                            answer: "Você pode enviar sugestões diretamente pelo WhatsApp, utilizando nosso assistente virtual."
                        },
                        {
                            question: "Posso falar diretamente com um especialista?",
                            answer: "Sim, você pode entrar em contato com nossos especialistas em horário comercial para dúvidas mais complexas."
                        },
                    ],
                },
            ],
        },
    ];

    return (
        <div className="pt-6 container">
            <div className="text-900 font-bold text-5xl mb-3">Perguntas Frequentes</div>
            <div className="flex flex-column">
                {faqData.map((faqCategory, index) => (
                    <FaqCategory key={index} faqCategory={faqCategory} />
                ))}
            </div>
        </div>
    );
}
