import React, { useState, useEffect } from 'react';
import { Chart } from "primereact/chart";

const COLORS = {
    portfolio: "#3b82f6", // Azul do Mini Dólar
    win: "#5fa8d3", // Azul claro para Mini Índice
    wdo: "#a3d8f4", // Azul mais claro
};

const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            labels: {
                color: "#1d4ed8",
            },
        },
    },
    scales: {
        x: {
            ticks: {
                color: "#1d4ed8",
            },
            grid: {
                offset: true,
            },
            categoryPercentage: 0.8,
            barPercentage: 0.9,
            offset: true,
        },
        y: {
            ticks: {
                color: "#1d4ed8",
            },
            beginAtZero: true,
        },
    },
    layout: {
        padding: {
            left: 20,
            right: 20,
        },
    },
    elements: {
        bar: {
            maxBarThickness: 40,
        },
    },
};

// Função para formatar as datas
const formatDates = (dates) => {
    return dates.map((date) => {
        const parts = date.split("/");
        if (parts.length === 3) {
            // Formato yyyy/mm/dd
            const [year, month, day] = parts;
            return `${day}/${month}/${year}`;
        } else if (parts.length === 2) {
            // Formato yyyy/mm
            const [year, month] = parts;
            return `${month}/${year}`;
        }
        return date; // Caso não seja nenhum dos dois formatos esperados
    });
};

const PortfolioChart = ({ portfolioData }) => {
    const { win, wdo, portfolio } = portfolioData;

    const [lineChartData, setLineChartData] = useState(null);
    const [barChartData, setBarChartData] = useState(null);
    const [step, setStep] = useState(0);

    useEffect(() => {
        if (step === 0) {
            // Renderiza apenas os dados do Mini Índice
            setLineChartData({
                labels: formatDates(win.returns.datas),
                datasets: [
                    {
                        label: `Mini Índice (${win.metrics.alpha_code})`,
                        data: win.returns.soma_acumulada_em_reais_mercado,
                        borderColor: COLORS.win,
                        fill: false,
                        tension: 0.4,
                    },
                ],
            });

            setBarChartData({
                labels: formatDates(win.returns.datas),
                datasets: [
                    {
                        label: `Mini Índice (${win.metrics.alpha_code})`,
                        data: win.returns.retornos_em_reais_mercado,
                        backgroundColor: COLORS.win,
                    },
                ],
            });

            // Avança para o próximo conjunto de dados
            setStep(1);
        }

        if (step === 1) {
            // Adiciona os dados do Mini Dólar
            setLineChartData((prevData) => ({
                ...prevData,
                datasets: [
                    ...prevData.datasets,
                    {
                        label: `Mini Dólar (${wdo.metrics.alpha_code})`,
                        data: wdo.returns.soma_acumulada_em_reais_mercado,
                        borderColor: COLORS.wdo,
                        fill: false,
                        tension: 0.4,
                    },
                ],
            }));

            setBarChartData((prevData) => ({
                ...prevData,
                datasets: [
                    ...prevData.datasets,
                    {
                        label: `Mini Dólar (${wdo.metrics.alpha_code})`,
                        data: wdo.returns.retornos_em_reais_mercado,
                        backgroundColor: COLORS.wdo,
                    },
                ],
            }));

            setStep(2);
        }

        if (step === 2) {
            // Adiciona os dados do Portfólio
            setLineChartData((prevData) => ({
                ...prevData,
                datasets: [
                    ...prevData.datasets,
                    {
                        label: "Portfólio",
                        data: portfolio.returns.soma_acumulada_retornos,
                        borderColor: COLORS.portfolio,
                        fill: false,
                        tension: 0.4,
                    },
                ],
            }));

            setBarChartData((prevData) => ({
                ...prevData,
                datasets: [
                    ...prevData.datasets,
                    {
                        label: "Portfólio",
                        data: portfolio.returns.soma_retornos,
                        backgroundColor: COLORS.portfolio,
                    },
                ],
            }));

            // Finaliza o processo
            setStep(3);
        }
    }, [step, win, wdo, portfolio]);

    return (
        <div>
            <div className="chart1">
                {lineChartData && (
                    <Chart
                        style={{ height: "300px", marginBottom: "1em" }}
                        type="line"
                        data={lineChartData}
                        options={chartOptions}
                    />
                )}
            </div>
            <div className="chart2">
                {barChartData && (
                    <Chart
                        style={{ height: "300px", marginBottom: "1em" }}
                        type="bar"
                        data={barChartData}
                        options={chartOptions}
                    />
                )}
            </div>
        </div>
    );
};

export default PortfolioChart;
