import React, { useEffect, useState } from "react";
import { Chart } from "primereact/chart";

const COLORS = {
    portfolio: "#3b82f6", // Azul para o Portfólio
    win: "#5fa8d3", // Azul claro para Mini Índice
    wdo: "#a3d8f4", // Azul mais claro para Mini Dólar
};

const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            labels: {
                color: "#1d4ed8",
            },
        },
    },
    scales: {
        x: {
            ticks: {
                color: "#1d4ed8",
            },
            grid: {
                offset: true,
            },
            categoryPercentage: 0.8,
            barPercentage: 0.9,
            offset: true,
        },
        y: {
            ticks: {
                color: "#1d4ed8",
            },
            beginAtZero: true,
        },
    },
    layout: {
        padding: {
            left: 30, // Espaço maior à esquerda
            right: 30, // Espaço maior à direita
        },
    },
    elements: {
        bar: {
            maxBarThickness: 40,
        },
    },
};

// Formatar datas (yyyy/mm para mm/yyyy, yyyy-mm-dd para dd/mm/yyyy, yyyy-mm para mm/yyyy)
const formatDates = (dates) => {
    return dates.map((date) => {
        if (date.includes("/")) {
            const parts = date.split("/");
            if (parts.length === 3) {
                // Formato yyyy-mm-dd -> dd/mm/yyyy
                const [year, month, day] = parts;
                return `${day}/${month}/${year}`;
            } else if (parts.length === 2) {
                // Formato yyyy-mm -> mm/yyyy
                const [year, month] = parts;
                return `${month}/${year}`;
            }
        }
        return date; // Retorna a data original se não corresponder a nenhum formato esperado
    });
};

const PortfolioChart3D = ({ portfolioData }) => {
    const [lineChartData, setLineChartData] = useState(null);
    const [barChartData, setBarChartData] = useState(null);

    useEffect(() => {
        if (!portfolioData) return;

        const { alphas_win, alphas_wdo, portfolio } = portfolioData;

        const lineDatasets = [
            ...alphas_win.map((alpha) => ({
                label: `Win (${alpha.metrics.alpha_code})`,
                data: alpha.returns.soma_acumulada_em_reais_mercado,
                borderColor: COLORS.win,
                fill: false,
                tension: 0.4,
            })),
            ...alphas_wdo.map((alpha) => ({
                label: `Wdo (${alpha.metrics.alpha_code})`,
                data: alpha.returns.soma_acumulada_em_reais_mercado,
                borderColor: COLORS.wdo,
                fill: false,
                tension: 0.4,
            })),
            {
                label: "Portfólio",
                data: portfolio.returns.soma_acumulada_retornos,
                borderColor: COLORS.portfolio,
                fill: false,
                tension: 0.4,
            },
        ];

        const barDatasets = [
            ...alphas_win.map((alpha) => ({
                label: `Win (${alpha.metrics.alpha_code})`,
                data: alpha.returns.retornos_em_reais_mercado,
                backgroundColor: COLORS.win,
            })),
            ...alphas_wdo.map((alpha) => ({
                label: `Wdo (${alpha.metrics.alpha_code})`,
                data: alpha.returns.retornos_em_reais_mercado,
                backgroundColor: COLORS.wdo,
            })),
            {
                label: "Portfólio",
                data: portfolio.returns.soma_retornos,
                backgroundColor: COLORS.portfolio,
            },
        ];

        setLineChartData({
            labels: formatDates(portfolio.returns.datas),
            datasets: lineDatasets,
        });

        setBarChartData({
            labels: formatDates(portfolio.returns.datas),
            datasets: barDatasets,
        });
    }, [portfolioData]);

    return (
        <div className="flex flex-column w-full gap-3">
            {/* Gráfico de linhas: Retornos Acumulados */}
            {lineChartData && (
                <div
                    style={{
                        height: "400px",
                        marginBottom: "1em",
                    }}
                >
                    <Chart
                        type="line"
                        data={lineChartData}
                        options={chartOptions}
                        style={{ height: "100%" }} // Garantir que o canvas herde o tamanho do container
                    />
                </div>
            )}

            {/* Gráfico de barras: Retornos por Período */}
            {barChartData && (
                <div
                    style={{
                        height: "400px",
                        marginBottom: "1em",
                    }}
                >
                    <Chart
                        type="bar"
                        data={barChartData}
                        options={chartOptions}
                        style={{ height: "100%" }} // Garantir que o canvas herde o tamanho do container
                    />
                </div>
            )}
        </div>
    );
};

export default PortfolioChart3D;
