import React, { useState, useEffect } from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

export default function PlanComparison() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState([]);
    const navigate = useNavigate();

    ReactGA.send({
        hitType: "pageview",
        page: "/planos",
        title: "Planos",
    });

    // Simulação de chamada de API para obter os detalhes dos planos
    useEffect(() => {
        const plansData = [
            {
                type: "Portfólio PF150",
                totalReturn: "R$ 32.292,04",
                maxDrawdown: "R$ 2.386,00",
                recommendedCapital: "R$ 3.500,00",
                monthlyReturn: "R$ 2.700,00",
                payoff: "R$ 129,17 (dia útil)",
                maxDrawdownTime: "32 dias",
                drawdownReturnPercent: "1353.40%",
                capitalReturnPercent: "922.63%",
                details: "Este portfólio é composto pelo Alpha ATMWINW301-CLEAR do mini índice, e pelo Alpha ATMWDOW101-CLEAR do mini dólar.",
                link: "/show-portifolio-automated/PF150",
            },
            {
                type: "Portfólio PF250",
                totalReturn: "R$ 57.623,08",
                maxDrawdown: "R$ 4.415,00",
                recommendedCapital: "R$ 6.500,00",
                monthlyReturn: "R$ 4.800,00",
                payoff: "R$ 230,49 (dia útil)",
                maxDrawdownTime: "32 dias",
                drawdownReturnPercent: "1305.17%",
                capitalReturnPercent: "886.51%",
                details: "Este portfólio é composto pelo Alpha ATMWINW501-CLEAR do mini índice, e pelo Alpha ATMWDOW201-CLEAR do mini dólar.",
                link: "/show-portifolio-automated/PF250",
            },
            {
                type: "Portfólio PF300",
                totalReturn: "R$ 80.845,71",
                maxDrawdown: "R$ 3.864,00",
                recommendedCapital: "R$ 7.000,00",
                monthlyReturn: "R$ 6.000,00",
                payoff: "R$ 323,38 (dia útil)",
                maxDrawdownTime: "9 dias",
                drawdownReturnPercent: "2092.28%",
                capitalReturnPercent: "1154.94%",
                details: "Este portfólio é composto pelo Alpha ATMWIN6151-CLEAR do mini índice, e pelo Alpha ATWDOB3011-CLEAR do mini dólar.",
                link: "/show-portifolio-automated/PF300",
            },
        ];

        setTimeout(() => {
            setData(plansData);
            setLoading(false);
        }, 1000); // Simulando o carregamento
    }, []);

    return (
        <>
            <div className="flex p-6 text-center justify-content-center">
                {loading && <ProgressSpinner />}
                {error && <>Erro ao carregar os Planos</>}
                {data && (
                    <div className="grid grid-nogutter col-12 gap-3 justify-content-center">
                        {data.map((plan, key) => (
                            <Card
                                key={key}
                                className="p-card p-component md:w-25rem flex flex-column justify-between"
                                style={{ minHeight: "500px" }}
                            >
                                <div className="p-card-body flex-grow-1">
                                    <div className="p-card-content">
                                        <div className="text-6xl text-blue-500 font-bold mb-3">{plan.type}</div>
                                        <div className="p-card-subtitle mb-4">
                                            <strong>Retorno Total:</strong> {plan.totalReturn}
                                        </div>
                                        <div className="p-card-subtitle mb-4">
                                            <strong>Drawdown Máximo:</strong> {plan.maxDrawdown}
                                        </div>
                                        <div className="p-card-subtitle mb-4">
                                            <strong>Capital Recomendado:</strong> {plan.recommendedCapital}
                                        </div>
                                        <div className="p-card-subtitle mb-4">
                                            <strong>Retorno Médio Mensal:</strong> {plan.monthlyReturn}
                                        </div>
                                        <div className="p-card-subtitle mb-4">
                                            <strong>Payoff:</strong> {plan.payoff}
                                        </div>
                                        <div className="p-card-subtitle mb-4">
                                            <strong>Tempo Máximo Drawdown:</strong> {plan.maxDrawdownTime}
                                        </div>
                                        <div className="p-card-subtitle mb-4">
                                            <strong>Retorno (% Drawdown Máximo):</strong> {plan.drawdownReturnPercent}
                                        </div>
                                        <div className="p-card-subtitle mb-4">
                                            <strong>Retorno (% Capital Recomendado):</strong> {plan.capitalReturnPercent}
                                        </div>
                                        <div className="p-card-subtitle mb-4">
                                            <strong>Detalhes:</strong> {plan.details}
                                        </div>
                                    </div>
                                </div>
                                <div className="p-card-footer">
                                    <Button
                                        label={`Ver ${plan.type}`}
                                        type="button"
                                        className="p-button-raised w-full mt-4"
                                        onClick={() => navigate(plan.link)}
                                    />
                                </div>
                            </Card>
                        ))}
                    </div>
                )}
            </div>
        </>
    );
}
