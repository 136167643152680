import React, { useState, useEffect, useRef } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import { amc_api } from "../../utils/api_controller";
import { Paginator } from "primereact/paginator";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { Chart } from "primereact/chart";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AlphaMetrics from "../listAllAlphas/AlphaMetrics";
import AlphaMetricsPortifolio from "./AlphaMetricsPortifolio";
import PortfolioMetrics from "./PortfolioMetrics";
import ReactGA from "react-ga4";
import AlphaChartPortfolio from './AlphaChartPortfolio';
import PortfolioChart from './PortfolioChart';

const COLORS = {
    win: "#50c0e6",
    wdo: "#3b82f6",
    portfolio: "#FF9800",
};

const corretoras = [
    { label: 'Clear Investimentos', value: 6 },
    { label: 'Rico Investimentos', value: 9 },
    { label: 'XP Investimentos', value: 8 },
];

const AlphaPortifolioAutomated = () => {
    const { brokerageId, alphaCodeWin, alphaCodeWdo, periodo } = useParams();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [alphasWin, setAlphasWin] = useState([]);
    const [alphasWdo, setAlphasWdo] = useState([]);
    const [currentPageWin, setCurrentPageWin] = useState(0);
    const [maxPagesWin, setMaxPagesWin] = useState(1);
    const [currentPageWdo, setCurrentPageWdo] = useState(0);
    const [maxPagesWdo, setMaxPagesWdo] = useState(1);

    // Se não vier um período da URL, define "12M" como default.
    // Se não vier uma corretora da URL, define Rico como default.
    const [selectedBrokerage, setSelectedBrokerage] = useState(brokerageId ? parseInt(brokerageId) : 9);

    const [selectedPeriod, setSelectedPeriod] = useState(periodo || "12M");
    const [granularity, setGranularity] = useState('month'); // Default é 'month'
    const [selectedAlphaWin, setSelectedAlphaWin] = useState(null);
    const [selectedAlphaWdo, setSelectedAlphaWdo] = useState(null);
    const [portfolioData, setPortfolioData] = useState(null);
    const winSectionRef = useRef(null);
    const wdoSectionRef = useRef(null);
    const portfolioRef = useRef(null);
    const [ordenacao, setOrdenacao] = useState('payout_diario_em_reais_asc');
    const [isAscendente, setIsAscendente] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();

    function formatBRL(number) {
        return number
            ? number.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
              })
            : "R$ 0,00";
    }

    // Carrega os dados iniciais da URL (parâmetros do brokerage_id, win_code, wdo_code, e periodo)
    useEffect(() => {
        if (brokerageId) {
            setSelectedBrokerage(parseInt(brokerageId));  // Converte para número se necessário
        }
        if (alphaCodeWin && alphaCodeWin !== 'none') {
            setSelectedAlphaWin({ codigo: alphaCodeWin });
        } else if (alphaCodeWin === 'none') {
            setSelectedAlphaWin(null);  // Permitir a seleção de um Alpha Mini Índice
        }
        
        // Se o valor de alphaCodeWdo for "none", permitir a seleção de um Alpha Mini Dólar.
        if (alphaCodeWdo && alphaCodeWdo !== 'none') {
            setSelectedAlphaWdo({ codigo: alphaCodeWdo });
        } else if (alphaCodeWdo === 'none') {
            setSelectedAlphaWdo(null);  // Permitir a seleção de um Alpha Mini Dólar
        }
            if (periodo) {
            setSelectedPeriod(periodo);
        }
    }, [brokerageId, alphaCodeWin, alphaCodeWdo, periodo]);

    // Atualiza a URL com base nas seleções
    useEffect(() => {
        if (selectedAlphaWin && selectedAlphaWdo && portfolioData) {
            navigate(`/criar-portifolio-automated/${selectedBrokerage}/${selectedAlphaWin.codigo}/${selectedAlphaWdo.codigo}/${selectedPeriod}`, { replace: true });
        }
    }, [selectedAlphaWin, selectedAlphaWdo, portfolioData, selectedBrokerage, selectedPeriod, navigate]);

    // Carrega os dados do portfólio quando win_code e wdo_code forem fornecidos pela URL
    useEffect(() => {
        if (selectedAlphaWin && selectedAlphaWdo) {
            setLoading(true);
            amc_api
                .post("alphaportifolio/get_portfolio/", {
                    win_code: selectedAlphaWin.codigo,
                    wdo_code: selectedAlphaWdo.codigo,
                    periodo: selectedPeriod,
                    granularity: granularity,
                })
                .then((res) => {
                    setPortfolioData(res.data);
                })
                .catch(() => setError(true))
                .finally(() => setLoading(false));
        }
    }, [selectedAlphaWin, selectedAlphaWdo, selectedPeriod, granularity]);

    // Não exibir as listas de alphas se os alphas já estiverem selecionados
    const shouldShowAlphaWinList = !selectedAlphaWin;
    const shouldShowAlphaWdoList = selectedAlphaWin && !selectedAlphaWdo && !portfolioData;

    const handleOrdenacao = (newOrdenacao) => {
        setLoading(true);
        setOrdenacao(newOrdenacao);
        setCurrentPageWin(0);
        setCurrentPageWdo(0);
    };

    const toggleAscendente = () => {
        setIsAscendente(!isAscendente);
        setLoading(true);
        setCurrentPageWin(0);
        setCurrentPageWdo(0);
    };

    // Carrega alphas do Mini Índice
    useEffect(() => {
        if (selectedBrokerage) {
            setLoading(true);
            const ordenacaoComSinal = isAscendente ? ordenacao + '_asc' : ordenacao + '_desc';

            amc_api
                .get(
                    `alphaportifolio/get_alphas_plans/?pagina=${currentPageWin + 1
                    }&quantidade=4&tipo_alpha_plan=automated&brokerage_id=${selectedBrokerage}&ativo=win&periodo=${selectedPeriod}&ordenacao=${ordenacaoComSinal}`
                )
                .then((res) => {
                    setAlphasWin(res.data.produtos);
                    setMaxPagesWin(Math.ceil(res.data.total_produtos / 4));
                    setTimeout(() => {
                        winSectionRef.current?.scrollIntoView({
                            behavior: "smooth",
                            block: "start",
                        });
                    }, 300);
                })
                .catch(() => setError(true))
                .finally(() => setLoading(false));
        }
    }, [selectedBrokerage, currentPageWin, selectedPeriod, ordenacao, isAscendente]);

    // Carrega alphas do Mini Dólar
    useEffect(() => {
        if (selectedAlphaWin) {
            setLoading(true);
            const ordenacaoComSinal = isAscendente ? ordenacao + '_asc' : ordenacao + '_desc';

            amc_api
                .get(
                    `alphaportifolio/get_alphas_plans/?pagina=${currentPageWdo + 1
                    }&quantidade=4&tipo_alpha_plan=automated&brokerage_id=${selectedBrokerage}&ativo=wdo&periodo=${selectedPeriod}&ordenacao=${ordenacaoComSinal}`
                )
                .then((res) => {
                    setAlphasWdo(res.data.produtos);
                    setMaxPagesWdo(Math.ceil(res.data.total_produtos / 4));
                    setTimeout(() => {
                        wdoSectionRef.current?.scrollIntoView({
                            behavior: "smooth",
                            block: "start",
                        });
                    }, 300);
                })
                .catch(() => setError(true))
                .finally(() => setLoading(false));
        }
    }, [selectedAlphaWin, currentPageWdo, selectedPeriod, ordenacao, isAscendente]);

    // Carrega dados do portfólio
    useEffect(() => {
        if (selectedAlphaWin && selectedAlphaWdo) {
            setLoading(true);
            amc_api
                .post("alphaportifolio/get_portfolio/", {
                    win_code: selectedAlphaWin.codigo,
                    wdo_code: selectedAlphaWdo.codigo,
                    periodo: selectedPeriod,
                    granularity: "month",
                })
                .then((res) => {
                    setPortfolioData(res.data);
                    setTimeout(() => {
                        portfolioRef.current?.scrollIntoView({
                            behavior: "smooth",
                        });
                    }, 300);
                })
                .catch(() => setError(true))
                .finally(() => setLoading(false));
        }
    }, [selectedAlphaWdo, selectedAlphaWin, selectedPeriod]);

    const handlePeriodSelection = (period) => {
        setSelectedPeriod(period);
        setCurrentPageWin(0);
        setCurrentPageWdo(0);
        setLoading(true);

        if (selectedAlphaWin) {
            amc_api
                .get(
                    `alphaportifolio/get_alphas_plans/?pagina=${currentPageWin + 1
                    }&quantidade=4&tipo_alpha_plan=automated&brokerage_id=${selectedBrokerage}&ativo=win&periodo=${period}&ordenacao=${ordenacao}`
                )
                .then((res) => {
                    setAlphasWin(res.data.produtos);
                    setMaxPagesWin(Math.ceil(res.data.total_produtos / 4));
                })
                .catch(() => setError(true));
        }

        if (selectedAlphaWin) {
            amc_api
                .get(
                    `alphaportifolio/get_alphas_plans/?pagina=${currentPageWdo + 1
                    }&quantidade=4&tipo_alpha_plan=automated&brokerage_id=${selectedBrokerage}&ativo=wdo&periodo=${period}&ordenacao=${ordenacao}`
                )
                .then((res) => {
                    setAlphasWdo(res.data.produtos);
                    setMaxPagesWdo(Math.ceil(res.data.total_produtos / 4));
                })
                .catch(() => setError(true));
        }

        if (selectedAlphaWin && selectedAlphaWdo) {
            amc_api
                .post("alphaportifolio/get_portfolio/", {
                    win_code: selectedAlphaWin.codigo,
                    wdo_code: selectedAlphaWdo.codigo,
                    periodo: period,
                    granularity: granularity,
                })
                .then((res) => {
                    setPortfolioData(res.data);
                })
                .catch(() => setError(true))
                .finally(() => setLoading(false));
        }
    };

    const handleBrokerageSelection = (brokerageId) => {
        setSelectedBrokerage(brokerageId);
        setCurrentPageWin(0);
        setCurrentPageWdo(0);
        setSelectedAlphaWin(null);
        setSelectedAlphaWdo(null);
        setPortfolioData(null);
        setLoading(true);
    };

    const onPageChangeWin = (event) => {
        setCurrentPageWin(event.page);
    };

    const onPageChangeWdo = (event) => {
        setCurrentPageWdo(event.page);
    };

    const handleSelectAlphaWin = (alpha) => {
        setSelectedAlphaWin(alpha);
        setCurrentPageWdo(0);
    };

    const handleSelectAlphaWdo = (alpha) => {
        setSelectedAlphaWdo(alpha);
    };

    const getAlphaCode = () => {
        return `${selectedAlphaWin.codigo},${selectedAlphaWdo.codigo}`;
    };

    // Função para atualizar a granularidade selecionada
    const handleGranularitySelection = (newGranularity) => {
        setGranularity(newGranularity);
        setLoading(true);
        
        // Recarrega o portfólio com a nova granularidade
        if (selectedAlphaWin && selectedAlphaWdo) {
            amc_api
                .post("alphaportifolio/get_portfolio/", {
                    win_code: selectedAlphaWin.codigo,
                    wdo_code: selectedAlphaWdo.codigo,
                    periodo: selectedPeriod,
                    granularity: newGranularity,
                })
                .then((res) => setPortfolioData(res.data))
                .catch(() => setError(true))
                .finally(() => setLoading(false));
        }
    };

    const handlePurchase = () => {
        const alphaDetails = {
            alpha_code: getAlphaCode(),
            ativo: "WINWDO",
            tipo: "PortifolioAutomated",
        };
        localStorage.setItem("purchaseDetails", JSON.stringify(alphaDetails));
        navigate("/cart");
    };

    const resetAlphaWin = () => {
        setSelectedAlphaWin(null);
        setAlphasWdo([]);
        setPortfolioData(null);
    };

    const resetAlphaWdo = () => {
        setSelectedAlphaWdo(null);
        setPortfolioData(null);
    };

    const renderPortfolioCharts = () => {
        if (!portfolioData) return null;

        const { win, wdo, portfolio } = portfolioData;

        return (
            <div className="container">
                <div ref={portfolioRef} className="flex flex-column w-full gap-3 mb-3">
                    <PortfolioMetrics data={portfolio.metrics} drawdown_wdo={win.metrics.drawdown_maximo_em_reais} drawdown_win={wdo.metrics.drawdown_maximo_em_reais} switchInterval={3} />
                    <PortfolioChart portfolioData={portfolioData} />
                    <AlphaMetrics data={win.metrics} unit="reais" questionColor="bg-blue-win" />
                    <AlphaMetrics data={wdo.metrics} unit="reais" questionColor="bg-blue-wdo" />
                </div>
            </div>
        );
    };

    return (
        <div className="p-6 text-center">
            {loading ? (
                <ProgressSpinner />
            ) : (
                <>
                    {error && <div className="text-red-500">Erro ao carregar Alphas</div>}

                    {/* Seleção da Corretora */}
                    <div className="text-center text-3xl font-bold mt-4">
                        Selecione a Corretora:
                    </div>
                    <div className="brokerage grid gap-1 justify-content-center mt-3 text-1xl text-center cursor-pointer">
                        {corretoras.map((corretora, key) => (
                            <div
                                key={key}
                                className={
                                    "pr-4 pl-4 pt-2 pb-2 shadow-1 br-1 transition-all transition-duration-400 " +
                                    (selectedBrokerage === corretora.value ? "text-gray-400 bg-bluegray-100" : "")
                                }
                                onClick={() => {
                                    if (selectedBrokerage !== corretora.value) handleBrokerageSelection(corretora.value);
                                }}
                                disabled={selectedBrokerage === corretora.value}
                            >
                                {corretora.label}
                            </div>
                        ))}
                    </div>

                    {/* Seleção do Período */}
                    <div className="text-center text-3xl font-bold mt-4">
                        Selecione o Período:
                    </div>
                    <div className="periods grid gap-1 justify-content-center mt-3 text-1xl text-center cursor-pointer">
                        {[
                            { label: "Desde de 2022", value: "ALL" },
                            { label: "Ano de 2022", value: "2022" },
                            { label: "Ano de 2023", value: "2023" },
                            { label: "Último ano", value: "12M" },
                            { label: "Últimos 6 meses", value: "6M" },
                            { label: "Últimos 3 meses", value: "3M" },
                        ].map((period, key) => (
                            <div
                                key={key}
                                className={
                                    "pr-4 pl-4 pt-2 pb-2 shadow-1 br-1 transition-all transition-duration-400 " +
                                    (selectedPeriod === period.value ? "text-gray-400 bg-bluegray-100" : "")
                                }
                                onClick={() => {
                                    if (selectedPeriod !== period.value) handlePeriodSelection(period.value);
                                }}
                                disabled={selectedPeriod === period.value}
                            >
                                {period.label}
                            </div>
                        ))}
                    </div>

                    {/* Sessão de Granularidade - exibida somente se os dois alphas foram selecionados */}
                    {selectedAlphaWin && selectedAlphaWdo && (
                        <div>
                            <div className="text-center text-3xl font-bold mt-4">Selecione a Granularidade:</div>
                            <div className="granularity grid gap-1 justify-content-center mt-3 text-1xl text-center cursor-pointer">
                                {[
                                    { label: "Dia", value: "day" },
                                    { label: "Mês", value: "month" },
                                ].map((option, key) => (
                                    <div
                                        key={key}
                                        className={
                                            "pr-4 pl-4 pt-2 pb-2 shadow-1 br-1 transition-all transition-duration-400 " +
                                            (granularity === option.value ? "text-gray-400 bg-bluegray-100" : "")
                                        }
                                        onClick={() => handleGranularitySelection(option.value)}
                                    >
                                        {option.label}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {!portfolioData && (
                        <>
                            {/* Botões de Ordenação */}
                            <div className="text-center text-3xl font-bold mt-4">
                                Ordenar por:
                            </div>
                            <div className="ordenacao grid gap-1 justify-content-center mt-3 text-1xl text-center cursor-pointer">
                                {[
                                    { tipo: 'payout_diario_em_reais', nome: 'Payoff' },
                                    { tipo: 'acuracia', nome: 'Acurácia' },
                                    { tipo: 'drawdown_maximo_em_reais', nome: 'Drawdown' }
                                ].map((item, key) => (
                                    <div
                                        key={key}
                                        className={
                                            "pr-4 pl-4 pt-2 pb-2 shadow-1 br-1 transition-all transition-duration-400 cursor-pointer " +
                                            (ordenacao.startsWith(item.tipo) ? "text-gray-400 bg-bluegray-100" : "")
                                        }
                                        onClick={() => {
                                            if (!ordenacao.startsWith(item.tipo)) handleOrdenacao(item.tipo);
                                        }}
                                        disabled={ordenacao.startsWith(item.tipo)}
                                    >
                                        {item.nome}
                                    </div>
                                ))}
                                <div
                                    className="pr-4 pl-4 pt-2 pb-2 shadow-1 br-1 transition-all transition-duration-400 cursor-pointer text-gray-800 bg-white"
                                    onClick={toggleAscendente}
                                >
                                    {isAscendente ? "Ordem Crescente" : "Ordem Decrescente"}
                                </div>
                            </div>
                        </>
                    )}

                    {/* Renderiza a lista de Alphas Mini Índice */}
                    {shouldShowAlphaWinList && (
                        <div ref={winSectionRef} className="alpha-list mt-5">
                            <div className="text-center text-3xl font-bold">
                                Selecione o Alpha Mini Índice
                            </div>
                            <div className="grid grid-nogutter col-12 gap-3 justify-content-center">
                                {alphasWin.map((produto, key) => (
                                    <AlphaBlockPortfolio
                                        key={key}
                                        data={produto}
                                        selectedPeriod={selectedPeriod}
                                        onSelectAlpha={handleSelectAlphaWin}
                                    />
                                ))}
                            </div>
                            <div className="card">
                                <Paginator
                                    first={currentPageWin}
                                    rows={1}
                                    totalRecords={maxPagesWin}
                                    onPageChange={onPageChangeWin}
                                />
                            </div>
                        </div>
                    )}

                    {/* Renderiza a lista de Alphas Mini Dólar */}
                    {shouldShowAlphaWdoList && (
                        <div ref={wdoSectionRef} className="alpha-list mt-5">
                            <div className="text-center text-3xl font-bold">
                                Selecione o Alpha Mini Dólar
                            </div>
                            <div className="grid grid-nogutter col-12 gap-3 justify-content-center">
                                {alphasWdo.map((produto, key) => (
                                    <AlphaBlockPortfolio
                                        key={key}
                                        data={produto}
                                        selectedPeriod={selectedPeriod}
                                        onSelectAlpha={handleSelectAlphaWdo}
                                    />
                                ))}
                            </div>
                            <div className="card">
                                <Paginator
                                    first={currentPageWdo}
                                    rows={1}
                                    totalRecords={maxPagesWdo}
                                    onPageChange={onPageChangeWdo}
                                />
                            </div>
                        </div>
                    )}

                    {/* Exibe os gráficos de portfólio */}
                    {selectedAlphaWin && selectedAlphaWdo && portfolioData && (

                        <div className="container">
                            <div className="header mt-5 mb-2 p-1">
                            <div className="identification text-left mb-4 pr-2">
                                <div className="text-3xl font-bold">
                                    Portfólio Automated:
                                </div>
                                <div className="text-1xl mb-3 text-justify">
                                    Este portfólio é composto pelo Alpha <strong>{portfolioData.win?.metrics?.alpha_code}</strong> do mini índice, e pelo Alpha <strong>{portfolioData.wdo?.metrics?.alpha_code}</strong> do mini dólar.
                                </div>
                                <div className="text-1xl mb-3 text-justify">
                                    Os valores de drawdown máximo individuais para os ativos são de <strong>{formatBRL(portfolioData.win?.metrics?.drawdown_maximo_em_reais)}</strong> para o mini índice e <strong>{formatBRL(portfolioData.wdo?.metrics?.drawdown_maximo_em_reais)}</strong> para o mini dólar, resultando em um total de <strong>{formatBRL(portfolioData.win?.metrics?.drawdown_maximo_em_reais + portfolioData.wdo?.metrics?.drawdown_maximo_em_reais)}</strong>.
                                </div>
                                <div className="text-1xl mb-3 text-justify">
                                    No portfólio combinado, o drawdown é reduzido para <strong>{formatBRL(portfolioData.portfolio?.metrics?.drawdown_maximo_em_reais)}</strong>, representando uma redução de 
                                    <span style={{ marginLeft: '4px', color: 'red' }}>
                                        <del>{formatBRL((portfolioData.win?.metrics?.drawdown_maximo_em_reais + portfolioData.wdo?.metrics?.drawdown_maximo_em_reais) - portfolioData.portfolio?.metrics?.drawdown_maximo_em_reais)}</del>
                                    </span> reais.
                                </div>
                                <div className="text-1xl mb-3 text-justify">
                                    A quantidade máxima de contratos para o mini índice é de <strong>{portfolioData.win?.metrics?.quant_maxima_de_contratos}</strong>, e para o mini dólar é de <strong>{portfolioData.wdo?.metrics?.quant_maxima_de_contratos}</strong>. 
                                    Essa quantidade é variável e depende das condições do mercado e da configuração do plano contratado, sendo ajustada automaticamente para manter o equilíbrio do portfólio e minimizar riscos.
                                </div>
                                {selectedPeriod === '12M' || selectedPeriod === 'ALL' ? (
                                    <div className="text-1xl text-justify">
                                        O valor necessário para operar este portfólio com segurança, considerando o <strong>drawdown operacional </strong> 
                                        (que corresponde à soma do drawdown das operações diárias com a maior variação negativa registrada no dia seguinte ao maior drawdown. 
                                        Não representa uma perda efetiva, mas sim a garantia necessária na conta para cobrir essas oscilações), 
                                        é de <strong>{formatBRL(portfolioData.portfolio?.metrics?.drawdown_maximo_em_reais)}</strong>.
                                    </div>
                                ) : (
                                    <div className="text-1xl text-justify">
                                        *O valor necessário para operar este portfólio com segurança só é exibido para o período dos últimos 12 meses ou para todo o histórico.
                                    </div>
                                )}
                            </div>

                                {/* Botões abaixo do texto */}
                                <div className="cta mt-4">
                                    <div className="flex gap-3 justify-center">
                                        <Button
                                            label={`Comprar por ${formatBRL(499)} por mês`}
                                            type="button"
                                            className="p-button-raised w-full"
                                            onClick={handlePurchase}
                                        />
                                        <Button
                                            label="Escolher novo Alpha Mini Índice"
                                            type="button"
                                            className="p-button-outlined w-full win-button"
                                            onClick={resetAlphaWin}
                                        />
                                        <Button
                                            label="Escolher novo Alpha Mini Dólar"
                                            type="button"
                                            className="p-button-outlined w-full wdo-button"
                                            onClick={resetAlphaWdo}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>


                    )}

                    {portfolioData && renderPortfolioCharts()}
                </>
            )}
        </div>
    );
};

function AlphaBlockPortfolio({ data, selectedPeriod, onSelectAlpha }) {
    const [loadingDetails, setLoadingDetails] = useState(true);
    const [errorDetails, setErrorDetails] = useState(false);
    const [alphaDetails, setAlphaDetails] = useState(null);

    useEffect(() => {
        amc_api
            .get(
                `alphaportifolio/get_details/?alpha_code=${data.codigo}&granularity=month&periodo=${selectedPeriod}`
            )
            .then((res) => {
                setAlphaDetails(res.data);
            })
            .catch(() => setErrorDetails(true))
            .finally(() => setLoadingDetails(false));
    }, [data.codigo, selectedPeriod]);

    function formatBRL(number) {
        return number
            ? number.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
              })
            : "R$ 0,00";
    }

    return (
        <Card className="md:w-25rem">
            <div className="p-card-title" data-pc-section="title">
                {data.nome_ativo}
            </div>
            <div className="p-card-title" data-pc-section="title">
                {data.tipo}
            </div>
            <div class="p-card-subtitle" data-pc-section="subtitle">
                {data.descricao}. Max. contratos por operação: {data.max_contratos}.
            </div>
            {loadingDetails ? (
                <ProgressSpinner />
            ) : errorDetails ? (
                <>Erro ao carregar detalhes.</>
            ) : (
                <>
                    <AlphaChartPortfolio
                        chartDataLine={
                            alphaDetails.returns.soma_acumulada_em_reais_mercado
                        }
                        chartDataBar={
                            alphaDetails.returns.retornos_em_reais_mercado
                        }
                        chartDates={alphaDetails.returns.datas}
                        chartType={"line"}
                        chartLabelLine={"Acumulado em R$"}
                        chartLabelBar={"Retornos R$ por mês"}
                        chartId={data.codigo}
                        chartHeight={"300px"}
                    />
                    <AlphaMetricsPortifolio
                        data={alphaDetails.metrics}
                        unit="reais"
                    />
                </>
            )}
            <Button
                label="Selecionar"
                type="button"
                className="p-button-raised w-full"
                onClick={() => onSelectAlpha && onSelectAlpha(data)}
            />
        </Card>
    );
}

export default AlphaPortifolioAutomated;