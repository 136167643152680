import React from "react";

const COLORS = {
    drawdown: "#3b82f6", // Cor para os valores e dados destacados
};

export default function ShowDrawDown({ drawdownList, title }) {
    // Função para formatar valores monetários em reais
    function formatBRL(number) {
        if (number === undefined || number === null) {
            return "R$ 0,00";
        }
        return number.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
        });
    }

    // Função para formatar datas no padrão brasileiro
    function formatDate(dateString) {
        const options = { year: "numeric", month: "2-digit", day: "2-digit" };
        const date = new Date(dateString);
        return date.toLocaleDateString("pt-BR", options);
    }

    const renderDrawdowns = (drawdowns) => {
        return drawdowns.map((drawdown, index) => (
            <div key={index} className="shadow-1 br-1 p-2 mt-2 mb-2">
                <h3 style={{ color: "black" }}>{`Drawdown #${index + 1}`}</h3> {/* Alterado para preto */}
                <div className="prsy-grid prsy-col4 justify-content-between text-center mt-3">
                    <div className="flex flex-column border-right-1 border-200">
                        <span style={{ fontSize: "1.2em", fontWeight: "bold", color: COLORS.drawdown }}>
                            {formatDate(drawdown.start_date)}
                        </span>
                        <span style={{ fontSize: "0.7em" }}>Início</span>
                    </div>
                    <div className="flex flex-column border-right-1 border-200">
                        <span style={{ fontSize: "1.2em", fontWeight: "bold", color: COLORS.drawdown }}>
                            {formatDate(drawdown.end_date)}
                        </span>
                        <span style={{ fontSize: "0.7em" }}>Fim</span>
                    </div>
                    <div className="flex flex-column border-right-1 border-200">
                        <span style={{ fontSize: "1.2em", fontWeight: "bold", color: COLORS.drawdown }}>
                            {formatBRL(drawdown.drawdown_em_reais)}
                        </span>
                        <span style={{ fontSize: "0.7em" }}>Drawdown (R$)</span>
                    </div>
                    <div className="flex flex-column">
                        <span style={{ fontSize: "1.2em", fontWeight: "bold", color: COLORS.drawdown }}>
                            {drawdown.duration || 0} dias
                        </span>
                        <span style={{ fontSize: "0.7em" }}>Duração</span>
                    </div>
                </div>
            </div>
        ));
    };

    return (
        <div>
            {/* Título */}
            <div className="mt-4">
                <h2 style={{ color: "black" }}>{title}</h2> {/* Alterado para preto */}
            </div>

            {/* Lista de Drawdowns */}
            {renderDrawdowns(drawdownList)}
        </div>
    );
}
