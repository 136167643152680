import React from "react";
import AlphaProductBlock from "./AlphaProductBlock";

export default function FeaturedAlphas() {
    return (
        <div className="flex p-6 text-center justify-content-center">
            <div className="alpha-details">
                <div className="text-center text-3xl font-bold">
                    Portfólios disponíveis:
                </div>
                <div className="flex justify-content-center">
                    <div className="grid grid-nogutter col-12 gap-3 justify-content-center">
                        <AlphaProductBlock 
                            portfolio_code="PF150" 
                        />
                        <AlphaProductBlock 
                            portfolio_code="PF250" 
                        />
                        <AlphaProductBlock 
                            portfolio_code="PF300" 
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
