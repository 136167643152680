import React, { useState, useEffect } from "react";
import { amc_api, clearSession } from "../../utils/api_controller";
import { ProgressSpinner } from "primereact/progressspinner";
import { Button } from "primereact/button";
import ShowJourney from "./ShowJourneyAll";
import ShowJornadaConsolidada from "./ShowJornadaConsolidada";
import UserPortfolio from "./UserPortfolio";
import ChangeAccountForm from "./ChangeAccountForm";
import introJs from "intro.js";
import "intro.js/introjs.css";
import { useLocation } from "react-router-dom";
import { Dialog } from "primereact/dialog"; // Importa o componente de modal

export default function MyAccount() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState("");
    const [activeTab, setActiveTab] = useState("UserPortfolio");
    const [tutorialStarted, setTutorialStarted] = useState(false);
    const [showModal, setShowModal] = useState(false); // Modal agora começa como falso
    const location = useLocation();

    const isCompraConcluida = location.pathname === "/compra-concluida";

    const welcomeMessage = (
        <div>
            <p><strong>Parabéns pela sua compra!</strong></p>
            <p>Você receberá em breve um e-mail com os detalhes da contratação do plano ou portfólio, incluindo informações sobre os <strong>ALPHAS</strong> que escolheu.</p>
            <p>Além disso, enviaremos uma mensagem no WhatsApp com o suporte habilitado para ajudá-lo com a <strong>configuração, troca de senha, cancelamento, sugestões e dúvidas</strong>.</p>
            <p>Agora, vamos para o tutorial para que você saiba como acessar suas jornadas e perfil.</p>
        </div>
    );

    const startIntro = () => {
        introJs().setOptions({
            steps: [
                { element: "#profile-tab", intro: "Aqui você pode acessar as informações do seu perfil.", position: "right" },
                { element: "#journeys-tab", intro: "Aqui estão suas jornadas. Vamos explorar como selecionar suas jornadas.", position: "right" },
                { element: "#plan-section", intro: "Aqui você pode selecionar o tipo de jornada: Standard, Dynamic ou Automated.", position: "bottom" },
                { element: "#asset-section", intro: "Após escolher o tipo de jornada, selecione o ativo: Mini Índice (WIN) ou Mini Dólar (WDO).", position: "bottom" },
                { element: "#status-section", intro: "Agora, selecione se deseja visualizar jornadas ativas ou arquivadas.", position: "bottom" },
                { element: "#logout-button", intro: "Clique aqui para sair da conta.", position: "left" }
            ],
            showStepNumbers: true,
            showBullets: false,
            nextLabel: "Próximo",
            prevLabel: "Anterior",
            doneLabel: "Concluir",
        }).start();
    };

    useEffect(() => {
        amc_api
            .get("alphaportifolio/get_jornadas_trader/")
            .then((res) => setData(res.data))
            .catch((error) => setError(true))
            .finally(() => setLoading(false));

        if (!loading && !error && data && isCompraConcluida && !tutorialStarted) {
            setShowModal(true); 
            setTutorialStarted(true);
        }
    }, [loading, data, error, tutorialStarted, isCompraConcluida]);

    return (
        <div className="container">
            <Dialog 
                header="Bem-vindo!" 
                visible={showModal} 
                style={{ width: '80vw', maxWidth: '600px' }}
                onHide={() => setShowModal(false)} 
                closable={false}
                modal={true}
            >
                {welcomeMessage}
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                    <Button 
                        label="Iniciar Tutorial" 
                        onClick={() => { setShowModal(false); startIntro(); }} 
                    />
                    <Button 
                        label="Fechar" 
                        className="p-button-secondary" 
                        onClick={() => setShowModal(false)} 
                    />
                </div>
            </Dialog>

            {loading && <ProgressSpinner />}
            {error && <>Erro ao carregar página</>}
            {data && (
                <div className="container">
                    <div className="text-3xl pt-3 font-bold">Minha Conta</div>
                    <div className="flex flex-wrap justify-between items-center my-4">
                        {/* Botões principais */}
                        <div className="flex flex-wrap gap-2">
                            <Button
                                id="profile-tab" 
                                label="Perfil"
                                type="button"
                                className={`w-full md:w-auto ${activeTab === "Perfil" ? "bg-yellow text-white borderRadius" : "bg-blue-win text-white borderRadius"}`}
                                onClick={() => setActiveTab("Perfil")}
                            />
                            <Button
                                id="journeys-tab"
                                label="Jornadas"
                                type="button"
                                className={`w-full md:w-auto ${activeTab === "Jornadas" ? "bg-yellow text-white borderRadius" : "bg-blue-win text-white borderRadius"}`}
                                onClick={() => setActiveTab("Jornadas")}
                            />
                            <Button
                                id="journeys-tab"
                                label="Jornadas Consolidadas"
                                type="button"
                                className={`w-full md:w-auto ${activeTab === "JornadasConsolidadas" ? "bg-yellow text-white borderRadius" : "bg-blue-win text-white borderRadius"}`}
                                onClick={() => setActiveTab("JornadasConsolidadas")}
                            />
                            <Button
                                id="journeys-tab"
                                label="Portfólio"
                                type="button"
                                className={`w-full md:w-auto ${activeTab === "UserPortfolio" ? "bg-yellow text-white borderRadius" : "bg-blue-win text-white borderRadius"}`}
                                onClick={() => setActiveTab("UserPortfolio")}
                            />
                            <Button
                                id="logout-button" 
                                label="Sair"
                                type="button"
                                className="w-full md:w-auto bg-blue-wdo text-white borderRadius"
                                onClick={clearSession}
                            />
                        </div>
                    </div>
                    {activeTab === "JornadasConsolidadas" && <ShowJornadaConsolidada />}
                    {activeTab === "UserPortfolio" && <UserPortfolio />}
                    {activeTab === "Jornadas" && <ShowJourney />}
                    {activeTab === "Perfil" && <ChangeAccountForm />}
                </div>
            )}
        </div>
    );
}
