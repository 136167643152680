import React, { useState, useEffect, useMemo } from "react";
import { amc_api } from "../../utils/api_controller";
import { ProgressSpinner } from "primereact/progressspinner";
import { Chart } from "primereact/chart";

const COLORS = {
    portfolio: "#3b82f6", // Azul do Mini Dólar
    win: "#5fa8d3", // Azul claro para Mini Índice
    wdo: "#a3d8f4", // Azul mais claro
};

const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            labels: {
                color: "#1d4ed8",
            },
        },
    },
    scales: {
        x: {
            ticks: {
                color: "#1d4ed8",
            },
            beginAtZero: true,
            offset: true, // Adiciona espaço nas extremidades
        },
        y: {
            ticks: {
                color: "#1d4ed8",
            },
            beginAtZero: true,
        },
    },
};

const alignDataByDates = (portfolio, win, wdo) => {
    const allDates = Array.from(
        new Set([
            ...(portfolio?.returns?.datas || []),
            ...(win?.returns?.datas || []),
            ...(wdo?.returns?.datas || []),
        ])
    ).sort();

    const alignValues = (dates, values) => {
        const map = dates.reduce((acc, date, index) => {
            acc[date] = values[index];
            return acc;
        }, {});

        return allDates.map((date) => map[date] || 0);
    };

    return {
        allDates,
        portfolioRetornos: alignValues(portfolio?.returns?.datas || [], portfolio?.returns?.retornos_em_reais || []),
        portfolioSoma: alignValues(portfolio?.returns?.datas || [], portfolio?.returns?.soma_acumulada || []),
        winRetornos: alignValues(win?.returns?.datas || [], win?.returns?.retornos_em_reais_bot || []),
        winSoma: alignValues(win?.returns?.datas || [], win?.returns?.soma_acumulada || []),
        wdoRetornos: alignValues(wdo?.returns?.datas || [], wdo?.returns?.retornos_em_reais_bot || []),
        wdoSoma: alignValues(wdo?.returns?.datas || [], wdo?.returns?.soma_acumulada || []),
    };
};

export default function UserPortfolio() {
    const [selectedCorretora, setSelectedCorretora] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [portfolioData, setPortfolioData] = useState(null);

    const corretoras = [
        { id: 6, nome: "Clear Investimentos" },
        { id: 9, nome: "Rico Investimentos" },
        { id: 8, nome: "XP Investimentos" },
    ];

    const fetchPortfolio = () => {
        if (!selectedCorretora) return;

        setLoading(true);
        setError(false);

        amc_api
            .get(`/alphaportifolio/get_brokerage_user_journey/${selectedCorretora}/`)
            .then((res) => {
                setPortfolioData(res.data);
            })
            .catch(() => setError(true))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        fetchPortfolio();
    }, [selectedCorretora]);

    const formatBRL = (number) =>
        number.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
        });

    const formatPercentage = (number) =>
        (number * 100).toFixed(2) + "%";

    const renderMetrics = (metrics) => (
        <div className="prsy-grid prsy-col4 justify-content-between text-center">
            <div className="flex flex-column border-right-1 border-200">
                <b>Acurácia</b>
                <span>{metrics ? formatPercentage(metrics.acuracia || 0) : "N/A"}</span>
            </div>
            <div className="flex flex-column border-right-1 border-200">
                <b>Drawdown</b>
                <span>
                    {metrics ? formatBRL(metrics.drawdown_operacional_em_reais || 0) : "N/A"}
                </span>
            </div>
            <div className="flex flex-column border-right-1 border-200">
                <b>Payoff</b>
                <span>{metrics ? (metrics.payoff || 0).toFixed(2) : "N/A"}</span>
            </div>
            <div className="flex flex-column">
                <b>Total</b>
                <span>{metrics ? formatBRL(metrics.total_em_reais || 0) : "N/A"}</span>
            </div>
        </div>
    );

    const isPortfolioEmpty = useMemo(() => {
        return (
            portfolioData &&
            (!portfolioData.portfolio?.returns?.datas?.length &&
                !portfolioData.alpha_win?.returns?.datas?.length &&
                !portfolioData.alpha_wdo?.returns?.datas?.length)
        );
    }, [portfolioData]);

    const alignedData = useMemo(() => {
        if (!portfolioData || isPortfolioEmpty) return null;
        return alignDataByDates(portfolioData.portfolio, portfolioData.alpha_win, portfolioData.alpha_wdo);
    }, [portfolioData, isPortfolioEmpty]);

    const lineChartData = useMemo(() => {
        if (!alignedData) return null;
    
        return {
            labels: alignedData.allDates,
            datasets: [
                {
                    label: "Soma Acumulada WIN",
                    data: alignedData.winSoma,
                    borderColor: COLORS.win,
                    fill: false,
                    tension: 0.4,
                    type: "line",
                },
                {
                    label: "Soma Acumulada WDO",
                    data: alignedData.wdoSoma,
                    borderColor: COLORS.wdo,
                    fill: false,
                    tension: 0.4,
                    type: "line",
                },
                {
                    label: "Soma Acumulada Portfolio",
                    data: alignedData.portfolioSoma,
                    borderColor: COLORS.portfolio,
                    fill: false,
                    tension: 0.4,
                    type: "line",
                },
            ],
        };
    }, [alignedData]);
    
    const barChartData = useMemo(() => {
        if (!alignedData) return null;
    
        return {
            labels: alignedData.allDates,
            datasets: [
                {
                    label: "Retornos WIN",
                    data: alignedData.winRetornos,
                    backgroundColor: COLORS.win,
                    type: "bar",
                },
                {
                    label: "Retornos WDO",
                    data: alignedData.wdoRetornos,
                    backgroundColor: COLORS.wdo,
                    type: "bar",
                },
                {
                    label: "Retornos Portfolio",
                    data: alignedData.portfolioRetornos,
                    backgroundColor: COLORS.portfolio,
                    type: "bar",
                },
            ],
        };
    }, [alignedData]);
    
    const getCorretoraNome = (id) => {
        const corretora = corretoras.find((c) => c.id === id);
        return corretora ? corretora.nome : "a corretora selecionada";
    };

    return (
        <div className="container">
            <h2 className="text-3xl font-bold text-center mb-4">Selecione a Corretora</h2>
            <div className="flex justify-content-center gap-2 mb-4">
                {corretoras.map((corretora) => (
                    <div
                        key={corretora.id}
                        className={`cursor-pointer p-2 shadow-1 br-1 ${
                            selectedCorretora === corretora.id
                                ? "bg-bluegray-100 text-gray-400"
                                : ""
                        }`}
                        onClick={() => setSelectedCorretora(corretora.id)}
                    >
                        {corretora.nome}
                    </div>
                ))}
            </div>

            {loading && <ProgressSpinner />}
            {error && (
                <p className="text-center text-red-500">
                    Erro ao carregar dados. Tente novamente.
                </p>
            )}
            {!loading && isPortfolioEmpty && (
                <p className="text-center text-xl">
                    Você não possui portfolio na <b>{getCorretoraNome(selectedCorretora)}</b>.
                </p>
            )}
            {!loading && portfolioData && !isPortfolioEmpty && (
                <>
                    <div className="shadow-1 br-1 p-2 mt-2 mb-2">
                        {renderMetrics(portfolioData.portfolio?.metrics)}
                    </div>
                    <div className="flex flex-column w-full gap-3 mb-3">
                        {lineChartData && (
                            <Chart
                                style={{ height: "300px", marginBottom: "1em" }}
                                type="line"
                                data={lineChartData}
                                options={chartOptions}
                            />
                        )}
                        {barChartData && (
                            <Chart
                                style={{ height: "300px", marginBottom: "1em" }}
                                type="bar"
                                data={barChartData}
                                options={chartOptions}
                            />
                        )}
                    </div>
                </>
            )}
        </div>
    );
}
