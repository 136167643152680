import React, { useState, useEffect, useRef } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import { Button } from "primereact/button";
import { amc_api } from "../../utils/api_controller";
import { useParams, useNavigate } from "react-router-dom";
import PortfolioMetrics from "./PortfolioMetrics2";
import PortfolioAlphaMetrics from "./PortfolioAlphaMetrics";
import ShowDrawDown from "./ShowDrawDown";
import PortfolioChart from "./PortfolioChart3D";

const ShowPortifolioAutomated = () => {
    const { portfolioCode } = useParams(); // Obtém o código do portfólio pela URL
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [portfolioData, setPortfolioData] = useState(null);
    const [portfolioCorretoraData, setPortfolioCorretoraData] = useState(null);
    const [selectedPeriod, setSelectedPeriod] = useState("12M"); // Período padrão
    const [selectedGranularity, setSelectedGranularity] = useState("month"); // Granularidade padrão
    const [selectedBrokerage, setSelectedBrokerage] = useState(null); // Corretora selecionada
    const portfolioRef = useRef(null);
    const navigate = useNavigate();

    const formatBRL = (number) =>
        number
            ? number.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
              })
            : "R$ 0,00";

    const fetchPortfolioDetails = async (period = "12M", granularity = "month") => {
        setLoading(true);
        setError(false);

        try {
            const portfolioCorretoraResponse = await amc_api.get(`/alphaportifolio/get_portfolio_by_code/${portfolioCode}/`);
            const portfolioCorretoraDetails = portfolioCorretoraResponse.data;

            // Define a corretora padrão apenas se nenhuma corretora estiver selecionada
            if (!selectedBrokerage) {
                setSelectedBrokerage(portfolioCorretoraDetails.brokerages[0]);
            }

            setPortfolioCorretoraData({
                ...portfolioCorretoraDetails,
                portfolioCorretoraDetails,
            });


            const portfolioResponse = await amc_api.get(`/alphaportifolio/get_portfolio_by_code2/${portfolioCode}/${selectedBrokerage.id}/${period}/${granularity}/`);
            const portfolioDetails = portfolioResponse.data;

            console.log('portfolioDetails -> ', portfolioDetails)

            setPortfolioData({
                ...portfolioDetails,
                portfolioDetails,
            });

            setTimeout(() => {
                portfolioRef.current?.scrollIntoView({
                    behavior: "smooth",
                });
            }, 300);
        } catch (err) {
            console.error("Erro ao carregar dados do portfólio:", err);
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    // Chamar fetchPortfolioDetails apenas quando `selectedBrokerage` estiver definido
    useEffect(() => {
        if (selectedBrokerage || !portfolioCorretoraData) {
            fetchPortfolioDetails(selectedPeriod, selectedGranularity);
        }
    }, [selectedPeriod, selectedGranularity, selectedBrokerage]);

    const handlePurchase = () => {
        const alphaDetails = {
            ativo: "PORTFOLIO",
            tipo: portfolioCorretoraData.portfolioCorretoraDetails.code,
            corretora: selectedBrokerage?.id.toString(), // Convertendo o ID para string
        };
        console.log('alphaDetails -> ', alphaDetails)
        localStorage.setItem("purchaseDetails", JSON.stringify(alphaDetails));
        navigate("/cart");
    };

    if (loading) {
        return <ProgressSpinner />;
    }

    if (error) {
        return <div className="text-red-500">Erro ao carregar dados do portfólio.</div>;
    }

    const { portfolioCorretora, portfolioCorretoraDetails } = portfolioCorretoraData;

    return (
        <div ref={portfolioRef} className="container">
            {/* Cabeçalho */}
            <div className="header mt-5 mb-2 p-1">
                <div className="identification text-left mb-4 pr-2">
                    <div className="text-3xl font-bold">Portfólio: {portfolioCorretoraDetails.code}</div>
                </div>
            </div>

            {/* Botões de corretoras */}
            <div className="brokerage-selection text-center mt-2">
                <div className="text-xl font-semibold mb-2">Selecione a Corretora:</div>
                <div className="flex gap-3 justify-content-center">
                    {portfolioCorretoraDetails.brokerages.map((brokerage) => (
                        <Button
                            key={brokerage.id}
                            label={`${brokerage.name}`}
                            className={`p-button-outlined ${
                                selectedBrokerage?.id === brokerage.id ? "p-button-primary" : ""
                            }`}
                            onClick={() => setSelectedBrokerage(brokerage)}
                        />
                    ))}
                </div>
            </div>

            {/* Botões de seleção de período */}
            <div className="period-selection text-center mt-2">
                <div className="text-xl font-semibold mb-2">Selecione o Período:</div>
                <div className="grid grid-nogutter col-12 gap-2 justify-content-center">
                    {[
                        { label: "Desde 2022", value: "ALL" },
                        { label: "Ano de 2022", value: "2022" },
                        { label: "Ano de 2023", value: "2023" },
                        { label: "Últimos 12 meses", value: "12M" },
                        { label: "Últimos 3 meses", value: "3M" },
                        { label: "Último mês", value: "1M" },
                    ].map((period) => (
                        <Button
                            key={period.value}
                            label={period.label}
                            className={`p-button-outlined ${
                                selectedPeriod === period.value ? "p-button-primary" : ""
                            }`}
                            onClick={() => setSelectedPeriod(period.value)}
                        />
                    ))}
                </div>
            </div>

            {/* Botões de seleção de granularidade */}
            <div className="granularity-selection text-center mt-2">
                <div className="text-xl font-semibold mb-2">Selecione a Granularidade:</div>
                <div className="grid grid-nogutter col-12 gap-2 justify-content-center">
                    {[
                        { label: "Mensal", value: "month" },
                        { label: "Diária", value: "day" },
                    ].map((granularity) => (
                        <Button
                            key={granularity.value}
                            label={granularity.label}
                            className={`p-button-outlined ${
                                selectedGranularity === granularity.value ? "p-button-primary" : ""
                            }`}
                            onClick={() => setSelectedGranularity(granularity.value)}
                        />
                    ))}
                </div>
            </div>

            {/* Gráficos do portfólio e métricas */}
            <div className="flex flex-column w-full gap-3 mb-3">
                <PortfolioMetrics portfolio={portfolioData.portfolio} />
                <PortfolioChart portfolioData={portfolioData} />
            </div>

            <div className="flex flex-column w-full gap-3 mb-3">
                <ShowDrawDown drawdownList={portfolioData.portfolio.metrics.drawdown_list} title="Lista de Drawdowns" />
            </div>

            {/* Gráficos do portfólio e métricas */}
            <div className="flex flex-column w-full gap-3 mb-3">
                <PortfolioAlphaMetrics alphas={portfolioData.alphas_win} type="WIN" />
                <PortfolioAlphaMetrics alphas={portfolioData.alphas_wdo} type="WDO" />
            </div>

            {/* Botão de automação */}
            <div className="header mt-5 mb-2 p-1">
                <div className="cta mt-4">
                    <Button
                        label={`Ativar na ${selectedBrokerage?.name} por ${formatBRL(portfolioCorretoraDetails.price)} por mês`}
                        type="button"
                        className="p-button-raised w-full"
                        onClick={handlePurchase}
                    />
                </div>
            </div>

        </div>
    );
};

export default ShowPortifolioAutomated;
