import React from "react";

export default function SectionIntro() {
    return (
        <div className="surface-100 p-5 text-700 text-center">
            <h2 className="text-5xl text-blue-600 font-bold mb-3">Bem-vindo ao Alpha Monkey Club</h2>
            <p className="text-2xl text-700 mb-4">
                No <strong>Alpha Monkey Club</strong>, transformamos o mercado financeiro com tecnologia de ponta. Utilizamos <strong>Inteligência Artificial</strong> e <strong>Big Data</strong> para gerar previsões precisas e práticas, ajudando você a investir com mais segurança em ativos como <em>mini índice</em>, <em>mini dólar</em> e <em>BOVA11</em>.
            </p>
            <p className="text-2xl text-700 mb-4">
                Como parceiros do <strong>programa NVIDIA Inception</strong>, trazemos inovação e performance para estratégias de investimento automatizadas. Nossos modelos identificam padrões e tendências de mercado para oferecer insights confiáveis e personalizados.
            </p>
            <p className="text-2xl text-700">
                Escolha entre portfólios automatizados que combinam dois ou mais <strong>Alphas</strong>, otimizados para os ativos disponíveis. Nossa missão é democratizar o acesso à tecnologia, ajudando investidores de todos os níveis a alcançar seus objetivos com soluções simples e eficazes.
            </p>
        </div>
    );
}
