import React, { useState, useEffect } from "react";
import { Card } from "primereact/card";
import { ProgressSpinner } from "primereact/progressspinner";
import { Chart } from "primereact/chart";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom"; // Importa o hook para navegação
import { amc_api } from "../../utils/api_controller";

const COLORS = {
    portfolio: "#3b82f6", // Cor do Mini Dólar
};

const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false,
        },
    },
    scales: {
        x: {
            ticks: {
                color: "#1d4ed8",
            },
            grid: {
                offset: true,
            },
            categoryPercentage: 0.6,
            barPercentage: 0.8,
        },
        y: {
            ticks: {
                color: "#1d4ed8",
            },
            beginAtZero: true,
        },
    },
    layout: {
        padding: {
            left: 20,
            right: 20,
        },
    },
};

export default function AlphaProductBlock({ portfolio_code }) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [portfolioData, setPortfolioData] = useState(null);
    const navigate = useNavigate(); // Hook para navegação

    useEffect(() => {
        const fetchPortfolioDetails = async () => {
            setLoading(true);
            setError(false);
            try {
                const portfolioResponse = await amc_api.get(`/alphaportifolio/get_portfolio_by_code/${portfolio_code}/`);
                const portfolioDetails = portfolioResponse.data;

                const win_code = portfolioDetails.brokerages[0]?.alphas_win[0]?.code;
                const wdo_code = portfolioDetails.brokerages[0]?.alphas_wdo[0]?.code;

                if (!win_code || !wdo_code) {
                    throw new Error("Códigos de alphas_win ou alphas_wdo não encontrados.");
                }

                const performanceResponse = await amc_api.post("/alphaportifolio/get_portfolio/", {
                    win_code,
                    wdo_code,
                    periodo: "12M",
                    granularity: "month",
                });

                setPortfolioData({
                    ...performanceResponse.data,
                    portfolioDetails,
                });
            } catch (err) {
                console.error("Erro ao carregar dados do portfólio:", err);
                setError(true);
            } finally {
                setLoading(false);
            }
        };

        fetchPortfolioDetails();
    }, [portfolio_code]);

    const formatBRL = (number) =>
        number.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
        });

    const formatDates = (dates) =>
        dates.map((date) => {
            const [year, month] = date.split("-");
            return `${month}/${year}`;
        });

    if (loading) {
        return <ProgressSpinner />;
    }

    if (error) {
        return <p>Erro ao carregar dados do portfólio.</p>;
    }

    const { portfolio, portfolioDetails } = portfolioData;

    const lineChartData = {
        labels: formatDates(portfolio.returns.datas),
        datasets: [
            {
                label: "Portfólio - Acumulado (R$)",
                data: portfolio.returns.soma_acumulada_retornos,
                borderColor: COLORS.portfolio,
                tension: 0.4,
                fill: false,
            },
        ],
    };

    // Função para navegar para o ShowPortifolioAutomated
    const navigateToPortfolio = () => {
        navigate(`/show-portifolio-automated/${portfolio_code}`);
    };

    return (
        <Card className="shadow-1 br-1 p-2 mt-2 mb-2">
            <div className="p-card-title">{portfolio_code} - Detalhes</div>

            {/* Métricas organizadas em 2x2 com bordas */}
            <div className="shadow-1 br-1 p-2 mt-2 mb-2">
                <div className="prsy-grid prsy-col2 justify-content-between text-center mt-3">
                    <div className="flex flex-column border-right-1 border-200">
                        <span style={{ fontSize: "1.2em", fontWeight: "bold", color: COLORS.portfolio }}>
                            {formatBRL(portfolio.metrics.total_retorno)}
                        </span>
                        <span style={{ fontSize: "0.7em" }}>Retorno Total</span>
                    </div>
                    <div className="flex flex-column">
                        <span style={{ fontSize: "1.2em", fontWeight: "bold", color: COLORS.portfolio }}>
                            {formatBRL(portfolio.metrics.drawdown_maximo_em_reais)}
                        </span>
                        <span style={{ fontSize: "0.7em" }}>Drawdown Máximo</span>
                    </div>
                </div>
                <div className="prsy-grid prsy-col2 justify-content-between text-center mt-3">
                    <div className="flex flex-column border-right-1 border-200">
                        <span style={{ fontSize: "1.2em", fontWeight: "bold", color: COLORS.portfolio }}>
                            {formatBRL(portfolioDetails.capital_recommended)}
                        </span>
                        <span style={{ fontSize: "0.7em" }}>Capital Recomendado</span>
                    </div>
                    <div className="flex flex-column">
                        <span style={{ fontSize: "1.2em", fontWeight: "bold", color: COLORS.portfolio }}>
                            {formatBRL(portfolioDetails.monthly_return)}
                        </span>
                        <span style={{ fontSize: "0.7em" }}>Retorno Médio Mensal</span>
                    </div>
                </div>
            </div>

            {/* Gráfico */}
            <div>
                <h3>Retorno Acumulado</h3>
                <div style={{ width: "100%", maxWidth: "800px", margin: "0 auto" }}>
                    <Chart
                        style={{ height: "400px", marginBottom: "1em" }}
                        type="line"
                        data={lineChartData}
                        options={chartOptions}
                    />
                </div>
                <div style={{ textAlign: "center", fontSize: "1.1em", marginTop: "10px", color: "#333" }}>
                    {formatBRL(portfolioDetails.price)} por mês
                </div>
            </div>

            {/* Botão para ir ao ShowPortifolioAutomated */}
            <div className="text-center mt-4">
                <Button
                    label="Ver Mais Detalhes do Portfólio"
                    className="p-button-outlined"
                    onClick={navigateToPortfolio}
                />
            </div>
        </Card>
    );
}
